import InterestCard from "./InterestCard"


/* Interests: video games, teaching, music, active lifestyles  */
function VideoGamesFront() {
    return (
        <div className="interest-front">
            <h4>Game Development</h4>
        </div>
    )
}

function VideoGamesBack() {
    return (
        <div className="interest-back">
            <p>
                Growing up playing video games, I wanted to connect this hobby 
                with my passion for computer science. Game development is a field that
                touches both computer science and video games. By my definition, 
                game development is a field that allows us to actualize ideas into 
                playable media experiences. I plan to continue pursuing the design 
                aspects of game development to learn about creating engaging and provoking
                experiences for the users and players.
            </p>
        </div>
    )
}

function TeachingFront() {
    return(
        <div className="interest-front">
            <h4>Teaching</h4>
        </div>
    )
}

function TeachingBack() {
    return (
        <div className="interest-back">
            <p>
                Teaching is a field that I have considered getting into. 
                I particularly enjoy working with the youth because I want to build strong academic
                foundations and help enforce positive values during the developmental stages. 
                I have taken many opportunities to work in teaching, tutoring, and childcare positions 
                that range from chess class assistant, math instructor, and summer camp counselor. 
                I have also taught programming at different levels that range from being a tutor for 
                elementary school level coding classes to being a teaching assistant for 
                Northeastern University's foundational CS courses.
            </p>
        </div>
    )
}

function MusicFront() {
    return(
        <div className="interest-front">
            <h4>Music</h4>
        </div>
    )
}

function MusicBack() {
    return (
        <div className="interest-back">
            <p>
                From taking music lessons and band classes as a kid to listening to 
                the throwback songs that remind me of childhood, I believe that music
                is an integral part of my life. I appreciate the level of creative expression
                that artists have when making music and the fanbase that grows from mutual 
                enjoyment of the artist's work. I love attending concerts and singing to 
                my heart's content at karaoke (despite singing out of pitch).
            </p>
        </div>
    )
}


export function VideoGamesInterest() {
    return <InterestCard front={<VideoGamesFront/>} back={<VideoGamesBack/>}/>
}

export function TeachingInterest() {
    return <InterestCard front={<TeachingFront/>} back={<TeachingBack/>}/>
}

export function MusicInterest() {
    return <InterestCard front={<MusicFront/>} back={<MusicBack/>}/>
}
import { useEffect } from "react";


interface TechLogoProps {
  tech: string;
  size: "small" | "medium" | "large";
}

const TECH_STACK : {[key:string] : {name: string, img: string}} = {
  REACT : {
      name: 'React',
      img: require('../assets/tech-icons/react.png')
  },
  JAVASCRIPT : {
      name: 'JavaScript',
      img: require('../assets/tech-icons/javascript.png')
  },
  HTML: {
      name: 'HTML',
      img: require('../assets/tech-icons/html.png')
  },
  CSS: { 
      name: 'CSS',
      img: require('../assets/tech-icons/css.png')
  },
  TYPESCRIPT: {
      name: 'TypeScript',
      img: require('../assets/tech-icons/typescript.png')
  },
  NEXTJS: {
      name: 'Next.js',
      img: require('../assets/tech-icons/nextjs.png')
  },
  FIREBASE: {
      name: 'Firebase',
      img: require('../assets/tech-icons/firebase.png')
  },
  TAILWINDCSS: {
      name: 'TailwindCSS',
      img: require('../assets/tech-icons/tailwindcss.png')
  },
  REACT_NATIVE: {
      name: 'React Native',
      img: require('../assets/tech-icons/react_native.png')
  },
  MONGODB: {
      name: 'MongoDB',
      img: require('../assets/tech-icons/mongodb.png')
  },
  GCP: {
      name: 'Google Cloud Platform',
      img: require('../assets/tech-icons/gcp.png')
  }, 
  CSHARP: {
      name: 'C#',
      img: require('../assets/tech-icons/csharp.png')
  },
  CPP: {
      name: "C++",
      img: require('../assets/tech-icons/cpp.png')
  },
  UNITY: {
      name: 'Unity',
      img: require('../assets/tech-icons/unity.png')
  },
  ANDROID: {
      name: 'Android',
      img: require('../assets/tech-icons/android.png')
  },
  JAVA: {
      name: 'Java',
      img: require('../assets/tech-icons/java.png')
  },
  KOTLIN: {
      name: 'Kotlin',
      img: require('../assets/tech-icons/kotlin.png')
  },
  PYTHON: {
      name: 'Python',
      img: require('../assets/tech-icons/python.png')
  },
  RACKET: {
      name: 'Racket',
      img: require('../assets/tech-icons/racket.png')
  },
}

export default function TechLogo(props: TechLogoProps) {

  useEffect(() => {
    console.log(TECH_STACK.REACT.img)
  }, []);
  const { tech, size } = props;
  const techStackInfo = TECH_STACK[tech];

  const logoSize = {
    "small": "48px",
    "medium": "96px",
    "large": "192px",
  };

  return (
    <div 
      className="tech-logo-container" 
      style={{width: logoSize[size], height: logoSize[size]}}>
      <img
        className="tech-logo"
        src={TECH_STACK[tech].img}
        alt={techStackInfo.name}
      />
    </div>
    
  );
}
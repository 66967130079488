import "../style/styles.css";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import PERSONAL from "../information/personal.json";
import Navigation from "../components/Navigation";
import profile_pic_jpg from "../assets/profile-picture.jpg";

export default function Home() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100vh",
      }}
    >
      <Navigation />
      <div className="personal-bio-container">
        <h1>Kobe Zheng</h1>
        <div className="profile-pic-container">
          <Link to="../about-me" className="learn-more-link">
            <h3>ABOUT ME</h3>
          </Link>
          <img
            className="profile-pic"
            src={profile_pic_jpg}
            alt="Kobe's Profile Picture"
          />
        </div>
        <span className="education-label">
          <h3>Computer Science and Game Development </h3>
          <h3>@ Northeastern University</h3>
        </span>

        <div className="description">
          <p>{PERSONAL.description}</p>
        </div>
        <div className="link-container">
          <Link to="../resume" className="homepage-link link-resume">
            <div className="homepage-link-button">
              <h4>RESUME</h4>
            </div>
          </Link>
          <Link to="../projects" className="homepage-link link-projects">
            <div className="homepage-link-button">
              <h4>PROJECTS</h4>
            </div>
          </Link>
        </div>
      </div>
      <Footer />
    </div>
  );
}

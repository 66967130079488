import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import Navigation from "../components/Navigation";
import "../style/styles.css";

export default function ProjectNotFound() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        height: "100vh",
      }}
    >
      <Navigation />
      <div className="error-container">
        <h2 className="error-text">Project Not Found</h2>
        <h4 className="error-text">
          Sorry, this project is not publicly available to view. Please contact me if you would like to see it!
        </h4>
        <Link to="../projects" className="homepage-link link-projects">
            <div className="homepage-link-button">
              <h4>PROJECTS</h4>
            </div>
          </Link>
      </div>
      <Footer />
    </div>
  );
}
import projects from "../information/projects.json"
import { ProjectProps } from "../utils/types"


export const PROJECTS : ProjectProps[] = projects as ProjectProps[]

export const MONTHS : {[key:string] : string} = {
    '01' : 'January',
    '02' : 'February',
    '03' : 'March',
    '04' : 'April',
    '05' : 'May',
    '06' : 'June',
    '07' : 'July',
    '08' : 'August',
    '09' : 'September',
    '10' : 'October',
    '11' : 'November',
    '12' : 'December',
}

export const TECH_STACK : {[key:string] : {name: string, img: string}} = {
    REACT : {
        name: 'React',
        img: "../assets/tech-icons/react.png"
    },
    JAVASCRIPT : {
        name: 'JavaScript',
        img: 'src/assets/tech-icons/javascript.png'
    },
    HTML: {
        name: 'HTML',
        img: 'src/assets/tech-icons/html.png'
    },
    CSS: { 
        name: 'CSS',
        img: 'src/assets/tech-icons/css.png'
    },
    TYPESCRIPT: {
        name: 'TypeScript',
        img: 'src/assets/tech-icons/typescript.png'
    },
    NEXTJS: {
        name: 'Next.js',
        img: 'src/assets/tech-icons/nextjs.png'
    },
    FIREBASE: {
        name: 'Firebase',
        img: 'src/assets/tech-icons/firebase.png'
    },
    TAILWINDCSS: {
        name: 'TailwindCSS',
        img: 'src/assets/tech-icons/tailwindcss.png'
    },
    REACT_NATIVE: {
        name: 'React Native',
        img: 'src/assets/tech-icons/react-native.png'
    },
    MONGODB: {
        name: 'MongoDB',
        img: 'src/assets/tech-icons/mongodb.png'
    },
    GCP: {
        name: 'Google Cloud Platform',
        img: 'src/assets/tech-icons/gcp.png'
    }, 
    CSHARP: {
        name: 'C#',
        img: 'src/assets/tech-icons/csharp.png'
    },
    CPP: {
        name: "C++",
        img: 'src/assets/tech-icons/cpp.png'
    },
    UNITY: {
        name: 'Unity',
        img: 'src/assets/tech-icons/unity.png'
    },
    ANDROID: {
        name: 'Android',
        img: 'src/assets/tech-icons/android.png'
    },
    JAVA: {
        name: 'Java',
        img: 'src/assets/tech-icons/java.png'
    },
    KOTLIN: {
        name: 'Kotlin',
        img: 'src/assets/tech-icons/kotlin.png'
    },
    PYTHON: {
        name: 'Python',
        img: 'src/assets/tech-icons/python.png'
    },
    RACKET: {
        name: 'Racket',
        img: 'src/assets/tech-icons/racket.png'
    },
}
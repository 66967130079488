import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import hamburger_png from "../assets/hamburger-menu.png";
import ReactGA from "react-ga4";

const measurementId = process.env.REACT_APP_GOOGLE_MEASUREMENT_ID;
ReactGA.initialize(measurementId!, {
  gaOptions: {
    siteSpeedSampleRate: 100
  }});

export default function Navigation() {
  const [mobileMode, setMobileMode] = useState(false);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    if (window.matchMedia("(max-width: 800px)").matches) {
      ReactGA.event({
        category: "mobile",
        action: "device_visit",
      });
      setMobileMode(true);
    } else {
      ReactGA.event({
        category: "pc",
        action: "device_visit",
      });
      setMobileMode(false);
    }
  }, []);
  return mobileMode ? <MobileNavigation /> : <FullScreenNavigation />;
}

function FullScreenNavigation() {
  return (
    <div className="navigation-container">
      <div className="navigation-lead-container">
        <h3>Kobe's Personal Website</h3>
      </div>
      <div className="navigation-options-container">
        <NavLink
          to=".."
          className={({ isActive, isPending }) =>
            isPending ? "nav-option" : isActive ? "nav-option-active" : "nav-option"
          }
        >
          <h4>Home</h4>
        </NavLink>
        <NavLink to="../about-me" className={({ isActive, isPending }) =>
            isPending ? "nav-option" : isActive ? "nav-option-active" : "nav-option"
          }>
          <h4>About Me</h4>
        </NavLink>
        <NavLink to="../error" className={({ isActive, isPending }) =>
            isPending ? "nav-option" : isActive ? "nav-option-active" : "nav-option"
          }>
          <h4>Interests</h4>
        </NavLink>
        <NavLink to="../resume" className={({ isActive, isPending }) =>
            isPending ? "nav-option" : isActive ? "nav-option-active" : "nav-option"
          }>
          <h4>Resume</h4>
        </NavLink>
        <NavLink to="../projects" className={({ isActive, isPending }) =>
            isPending ? "nav-option" : isActive ? "nav-option-active" : "nav-option"
          }>
          <h4>Projects</h4>
        </NavLink>
      </div>
    </div>
  );
}

function MobileNavigation() {
  const [menuOpen, setMenuOpen] = useState(false);

  return menuOpen ? (
    <div className="navigation-container">
      <div className="navigation-options-container">
        <NavLink to=".." className="nav-option">
          <h4>Home</h4>
        </NavLink>
        <NavLink to="../about-me" className="nav-option">
          <h4>About Me</h4>
        </NavLink>
        <NavLink to="../error" className="nav-option">
          <h4>Interests</h4>
        </NavLink>
        <NavLink to="../resume" className="nav-option">
          <h4>Resume</h4>
        </NavLink>
        <NavLink to="../projects" className="nav-option">
          <h4>Projects</h4>
        </NavLink>
        <div className="nav-option">
          <h4
            onClick={() => {
              setMenuOpen(false);
            }}
          >
            Close
          </h4>
        </div>
      </div>
    </div>
  ) : (
    <div className="collapsed-navigation-container">
      <img
        className="hamburger-icon"
        src={hamburger_png}
        alt="menu"
        onClick={() => {
          setMenuOpen(true);
        }}
      ></img>
    </div>
  );
}

import { InterestCardProps } from "../utils/types";


export default function InterestCard(content : InterestCardProps) {
    return (
        <div className="interest-card">
            <div className="interest-card-inner">
                <div className="interest-card-front">
                    {content.front}
                </div>
                <div className="interest-card-back">
                    {content.back}
                </div>
            </div>
        </div>
    )
}
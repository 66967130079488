import { useEffect, useState } from "react";
import Footer from "../components/Footer";
import Navigation from "../components/Navigation";
import Project from "../components/Project";
import { PROJECTS } from "../utils/constants";
import { sortByAZ, sortByZA, sortByEarliest, sortByLatest } from "../utils/tools";

function Pager({pageNumber, setPageNumber, maxPageNumber}: {
    pageNumber: number, 
    setPageNumber: Function, 
    maxPageNumber: number
}) {
    return (
        <div className="pager-container">
            <button 
                className={"pager-button" + (pageNumber > 1 ? "" : " hidden")} 
                onClick={() => setPageNumber(Math.max(pageNumber - 1, 1))}>
                    <p className="pager-button-text">Back</p>
            </button>
            <p className="page-number">{pageNumber}</p>
           
                <button 
                    className={"pager-button" + (pageNumber < maxPageNumber ? "" : " hidden")}
                    onClick={() => setPageNumber(Math.min(pageNumber + 1, maxPageNumber))}>
                        <p className="pager-button-text">Next</p>
                </button>
        </div>
    )
}

function Projects() {
    const [sortFunction, setSortFunction] = useState("default");

    const PROJECTS_PER_PAGE = 6;
    const [pageNumber, setPageNumber] = useState(1);

    var projectList = PROJECTS

    // function changeSortMode() {
    //     if (sortFunction == 'sort-latest') {
    //         projectList = sortByLatest(PROJECTS);
    //         console.log("sort 1");
    //         console.log(projectList);
    //     } else if (sortFunction == 'sort-earliest') {
    //         projectList = sortByEarliest(PROJECTS);
    //         console.log("sort 2");
    //         console.log(projectList);
    //     } else if (sortFunction == 'sort-az') {
    //         projectList = sortByAZ(PROJECTS);
    //         console.log("sort 3");
    //         console.log(projectList);
    //     } else if (sortFunction == 'sort-za') {
    //         projectList = sortByZA(PROJECTS);
    //         console.log("sort 4");
    //         console.log(projectList);
    //     }
    // }
    useEffect(() => {
        const sortByLatestOption = document.getElementById('sort-latest') as HTMLInputElement;
        const sortByEarliestOption = document.getElementById('sort-earliest') as HTMLInputElement;
        const sortByAZOption = document.getElementById('sort-az') as HTMLInputElement;
        const sortByZAOption = document.getElementById('sort-za') as HTMLInputElement;
        if(sortByLatestOption.checked) {
            setSortFunction('sort-latest');
            projectList = sortByLatest(PROJECTS);
            console.log("1");
            console.log(projectList);
        } else if(sortByEarliestOption.checked) {
            setSortFunction('sort-earliest');
            projectList = sortByEarliest(PROJECTS);
            console.log("2");
            console.log(projectList);
        } else if(sortByAZOption.checked) {
            setSortFunction('sort-AZ');
            projectList = sortByAZ(PROJECTS);
            console.log("3");
            console.log(projectList);
        } else if(sortByZAOption.checked) {
            setSortFunction('sort-ZA');
            projectList = sortByZA(PROJECTS);
            console.log("4");
            console.log(projectList);
        }
    })
    return (
        <div 
            style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "100vh",
            }}
        >
            <Navigation />
            <div>
                <div className="centered-container header">
                    <h2>Works</h2>
                </div>
                <div className="filter-projects-container">
                    <fieldset>
                        <legend>Sort By</legend>
                        <label><input type="radio" name="sort" id="sort-latest" onClick={()=> {setSortFunction("latest");}}/>Latest</label>
                        <label><input type="radio" name="sort" id="sort-earliest" onClick={()=>{setSortFunction("earliest");}}/>Earliest</label>
                        <label><input type="radio" name="sort" id="sort-az" onClick={()=>{setSortFunction("az");}}/>A-Z</label>
                        <label><input type="radio" name="sort" id="sort-za" onClick={()=>{setSortFunction("za");}}/>Z-A</label>
                    </fieldset>
                    
                </div>
                <div className="projects-container">
                    {
                        // projectList.map((entry : ProjectProps, index: number) => (
                        //     <Project {...entry} key={entry.id}/>
                        // ))
                        projectList
                        .filter((entry, index) => {
                        const startIndex = (pageNumber - 1) * PROJECTS_PER_PAGE;
                        const endIndex = pageNumber * PROJECTS_PER_PAGE;
                        return index >= startIndex && index < endIndex;
                        })
                        .map((entry) => (
                        <Project {...entry} key={entry.id} />
                        ))
                    }
                    <Pager pageNumber={pageNumber} setPageNumber={setPageNumber} maxPageNumber={Math.ceil(projectList.length / PROJECTS_PER_PAGE)}/>
                </div>  
            </div>
            <Footer />
        </div>
    )
}

export default Projects;
import Footer from "../components/Footer";
import Navigation from "../components/Navigation";
import studying_kobe_jpg from "../assets/studying-kobe.jpg";
import khoury_jpg from "../assets/khoury.jpg";
import library_jpg from "../assets/library.jpg";
import classroom_jpg from "../assets/tech-classroom.jpg";


export default function AboutMe() {
    return ( 
        <div>
            <Navigation />
            <div className="about-me-container">
                <h2>ABOUT ME</h2>
                <span className="about-me-span">
                    <span className="about-me-frame">
                        <img src={studying_kobe_jpg} alt="resume"></img>
                    </span>
                    <span className="about-me-description-right">
                        <h4>WHO AM I?</h4>
                        <p>
                            My name is Kobe Zheng. <br />
                            I am from Queens, New York and 
                            I am currently an undergraduate student studying 
                            Computer Science and Game Development at Northeastern University.
                        </p>
                    </span>
                </span>

                <span className="about-me-span">
                    <span className="about-me-description-left">
                        <h4>WHAT EDUCATION DO I HAVE?</h4>
                        <p>
                            Learning to program and studying computer science for several years, 
                            I have taken introductory and foundational CS courses as well as principle CS and game design courses. <br/>
                        </p>
                    </span>
                    <span className="about-me-frame">
                        <img src={khoury_jpg} alt="resume"></img>
                    </span>
                </span>

                <span className="about-me-span">
                    <span className="about-me-frame">
                        <img src={library_jpg} alt="resume"></img>
                    </span>
                    <span className="about-me-description-right">
                        <h4>WHAT EXPERIENCE DO I HAVE?</h4>
                        <p>
                            I have worked on many academic, organization, and personal projects. <br />
                            Programming languages I know: <br/>
                            Java, Python, HTML/CSS, JavaScript, TypeScript, C++, C#, Racket<br />
                            Technologies I have experience in: <br />
                            ReactJS, React Native <br />
                            Check out more on the Projects Page
                        </p>
                    </span>
                </span>

                <span className="about-me-span">
                    <span className="about-me-description-left">
                        <h4>WHAT ARE MY INTERESTS IN COMPUTER SCIENCE?</h4>
                        <p>
                            I enjoy software development. I currently have more experience in front-end development, 
                            but I want to gain the experience to be a full-stack developer. <br/>
                            My interests include exploring how we can improve the lives of people through software
                            and how we can better incorporate technology into education, specifically the gamification of learning systems
                            to create interactive and rewarding learning experiences for the developing youth. 
                        </p>
                    </span>
                    <span className="about-me-frame">
                        <img src={classroom_jpg} alt="resume"></img>
                    </span>
                </span>

            </div>
            <Footer />
            
        </div>
    )
}
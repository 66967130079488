import { useState } from "react";
import { ProjectProps } from "../utils/types"
import { convertDateToText } from "../utils/tools";
import TechLogo from "./TechLogo";
import ReactGA from "react-ga4";

function Project(projectInfo : ProjectProps) {
    const [expanded, setExpanded] = useState(false);

    function trackExpandProject(projectId: string) {
        ReactGA.event({
            category: "view_project",
            action: "expand_project",
            label: projectId,
        });
    }

    return (
        <div className="project-container">
            <div className="project-content-container">
                <h3>{projectInfo.title}</h3>
                <p className="project-description">
                    {projectInfo.description}
                </p>
                {(expanded) &&
                    (<span>
                        <section className="tech-stack-container">
                            {projectInfo.tech_stack.map((tech) => <TechLogo tech={tech} size="small" key={tech + "_for_" + projectInfo.id}/>)}
                        </section>
                        <a href={projectInfo.link} target="_blank">Link to Project</a>
                        <p><i>{convertDateToText(projectInfo.date)}</i></p>
                        <ul>
                            {projectInfo.key_points.map((point) => <li key={point}>{point}</li>)}
                        </ul>
                    </span>)
                }
                
            </div>
            <button 
                className="project-expand-button"
                onClick={() => {setExpanded(!expanded); trackExpandProject(projectInfo.id)}}>
                <img 
                    className={(expanded) ? "arrow-img-collapse" : "arrow-img-expand"}
                    src={require("../assets/arrow-icon.png")}/>
            </button>
        </div>
    )
}

export default Project;

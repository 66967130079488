import Footer from "../components/Footer";
import Navigation from "../components/Navigation";
import resume_jpg from "../assets/Kobe_Zheng_Co-op_Resume.jpg";


function Resume() {
    return (
        <div>
            <Navigation />
            <div className="centered-container">
                <h2>RESUME</h2>
                <img className="resume-image" src={resume_jpg} alt="resume"></img>
            </div>
            <Footer />
        </div>
    )
}

export default Resume;
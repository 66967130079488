import '../style/styles.css'
import PERSONAL from "../information/personal.json"

function Socials() {
    return (
        <span className="social-links-container">
            <a href={PERSONAL.linkedin} target="_blank">
                <img className="small-icon" src={require("../assets/linkedin-icon.png")} alt="LinkedIn" />
            </a>
            <a href={PERSONAL.github} target="_blank"> 
                <img className="small-icon" src={require("../assets/github-icon.png")} alt="GitHub" /> 
            </a>
            <a href={PERSONAL.email} target="_blank"> 
                <img className="small-icon" src={require('../assets/email-icon.png')}  alt="Email" /> 
            </a>
        </span>
    )
}

export default Socials;
import Footer from "../components/Footer";
import Navigation from "../components/Navigation";

export default function PageNotFound() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        height: "100vh",
      }}
    >
      <Navigation />
      <div className="error-container">
        <h2 className="error-text">Page Not Found</h2>
        <h4 className="error-text">
          This page is still under development but expect it to be up soon!
        </h4>
      </div>
      <Footer />
    </div>
  );
}

import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider, createBrowserRouter, createRoutesFromElements, BrowserRouter, Routes, Route } from 'react-router-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';

import Home from './navigation/Home'
import Resume from './navigation/Resume'
import Projects from './navigation/Projects'
import PageNotFound from './navigation/PageNotFound';
import AboutMe from './navigation/AboutMe';
import Interests from './navigation/Interests';
import ProjectNotFound from './navigation/ProjectNotFound';
 
// this router supports data APIs but it is not deployed
const router = createBrowserRouter([
    {
      path: "/",
      element: <Home />,
      children: [
        {
          path: "resume",
          element: <Resume />,
        },
      ],
    }
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/error" element={<PageNotFound />} />
        <Route path="/resume" element={<Resume />} />
        <Route path="/projects" element={<Projects />} />  
        <Route path="/about-me" element={<AboutMe />} />
        <Route path="/interests" element={<Interests />} />
        <Route path="/project-not-found" element={<ProjectNotFound />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
  
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

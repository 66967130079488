import { MONTHS, PROJECTS } from "./constants";
import { ProjectProps } from "./types";

// given a date shorthand, returns the date in text-format (YYYY-MM-DD)
export function convertDateToText(date : string) {
    var date_sections = date.split("-");
    if (date_sections.length > 1) {
        var dateText = MONTHS[date_sections[1]] + " " + date_sections[0];
        return dateText;
    } else {
        if (date === '99') {
            return "ongoing";
        }
        if (date === '0') {
            return "not set";
        }
    }
    return date; 
}

// given an array of ProjectProps, sort the ProjectProps by earliest date 
export function sortByEarliest(projects : ProjectProps[]) {
    projects.sort((a, b) => {return a.date.localeCompare(b.date)})
    return projects;
}

// given an array of ProjectProps, sort the ProjectProps by earliest date 
export function sortByLatest(projects : ProjectProps[]) {
    projects.sort((a, b) => {return b.date.localeCompare(a.date)})
    return projects;
}

// given an array of ProjectProps, sort the ProjectProps by alphabetical order A-Z
export function sortByAZ(projects : ProjectProps[]) {
    projects.sort((a, b) => {return a.id.localeCompare(b.id)})
    return projects;
}

// given an array of ProjectProps, sort the ProjectProps by alphabetical order Z-A
export function sortByZA(projects : ProjectProps[]) {
    projects.sort((a, b) => {return b.id.localeCompare(a.id)})
    return projects;
}










import Footer from "../components/Footer";
import HobbyCard from "../components/InterestCard";
import { MusicInterest, TeachingInterest, VideoGamesInterest } from "../components/InterestCardVariations";
import Navigation from "../components/Navigation";

export default function Interests() {
    return (
        <div>
            <Navigation />
            <div className="interests-container">
                <div className="centered-container header">
                    <h2>Interests</h2>
                </div>
                <HobbyCard front={<div><h2>FRONT</h2></div>} back={<div><h2>BACK</h2></div>}/>
                <VideoGamesInterest />
                <TeachingInterest />
                <MusicInterest />
            </div>
            <Footer />
        </div>
    )
}